import React, { Component } from "react";
import PropTypes from "prop-types";
import { Navbar, NavLink } from "reactstrap";
import { Events, animateScroll as scroll, scroller } from "react-scroll";

class TYNavigation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      navTo: props.navTo ? props.navTo : "#page-top"
    };
    this.scrollToTop = this.scrollToTop.bind(this);
  }
  componentDidMount() {
    Events.scrollEvent.register("begin", function() {
      // console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function() {
      // console.log("end", arguments);
    });
  }
  scrollToTop() {
    scroll.scrollToTop();
  }
  scrollTo() {
    scroller.scrollTo("scroll-to-element", {
      duration: 400,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }
  render() {
    return (
      <div>
        <Navbar className="bg-dark" dark fixed="top" expand="md">
          <NavLink
            href={this.state.navTo}
            to={this.state.navTo}
            spy={true.toString()}
            smooth={true.toString()}
            onClick={() => this.scrollTo()}
          >
            jetsquared
          </NavLink>
        </Navbar>
      </div>
    );
  }
}

TYNavigation.propTypes = {
  navTo: PropTypes.string
};

export default TYNavigation;
